import { makeAutoObservable, autorun } from 'mobx'
import { request, postAuth } from '@/services/adapters/req'

class Auth {
  isLoading = false
  isAuthorization = false
  isError = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value
  }

  setIsAuthorization = (value: boolean) => {
    this.isAuthorization = value
  }

  checkToken() {
    const token = null
    if (token) {
      this.setIsLoading(false)
      this.setIsAuthorization(true)
    }
  }

  send = async (body: { client_id: string; client_secret: string }) => {
    if (this.isAuthorization || this.isLoading) {
      return
    }
    this.setIsLoading(true)
    await request(postAuth.endpoint, postAuth, body)
    this.setIsLoading(false)
    this.setIsAuthorization(true)
  }
}

const storeAuth = new Auth()

autorun(() => storeAuth.checkToken())

export { storeAuth }
